<template>
  <div class="video-details-field d-flex flex-row align-start mt-3">
    <div class="col-2 pa-0" v-if="label">
      <v-chip class="secondary" x-small>{{label}}</v-chip>
    </div>
    <p class="grey--text text--darken-2" style="white-space:pre-wrap;" v-if="parse" v-html="getHTML(text)">
    </p>
    <p class="grey--text text--darken-2" style="white-space:pre-wrap;" v-else>
      {{getHTML(text)}}
    </p>
  </div>
</template>

<script>


export default {
  name: "video-details-field",
  props: ['label','text', 'parse'],
  data(){
    return{
      test: "",
    }
  },
  methods: {
    getHTML(text){
      if(this.parse){
        let content = JSON.parse(text)
        let escapeURL = content.replace(/\r?\n/g, "<br />"); //eslint-disable-line
        var re_weburl = /(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig; //eslint-disable-line
        // process.env.NODE_ENV === "development" ? console.log( 'regexp test', escapeURL, escapeURL.match(re_weburl) ) : null;
        let returnable = escapeURL.replace(re_weburl, (match) => {
          // process.env.NODE_ENV === "development" ? console.log( 'match', match ) : null;
          return `<a href="${match}" target="_blank">${match}</a>`;
        });
        return returnable

      }
      else{
        return text
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.video-details-field{
  max-width: 100%;
}
</style>
